.project {
    position: relative;
    width: 400px;
    min-width: 330px;
    height: 550px;
    background-color: var(--navbar-background);
    border-radius: 20px;
    /* border: 3px solid var(--navbar-background); */
}

.project:hover {
    cursor: pointer;
}

.project::after {
    position: absolute;
    content: "";
    bottom: 0%;
    border: none;
    border-radius: 20px;
    transition: 0.3s;
}

.project:hover::after {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff, 0 0 2rem var(--neon-shadow),
        0 0 0.8rem var(--neon-shadow), 0 0 2.8rem var(--neon-shadow),
        inset 0 0 1.3rem var(--neon-shadow);
    border: 0.1rem solid var(--neon-text);
}

.project img {
    position: absolute;
    width: 95%;
    height: 37.5%;
    top: 2.5%;
    left: 2.5%;
    /* border: 3px solid var(--navbar-background); */
    border-radius: 20px;
    pointer-events: none;
}

.project p {
    position: absolute;
    top: 42.5%;
    left: 2.5%;
    width: 95%;
    height: 55%;
    padding: 3%;
    color: lightskyblue;
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0.2rem;
    background-color: var(--portfolio-background);
    /* border: 3px solid var(--navbar-background); */
    border-radius: 20px;
    pointer-events: none;
}

.project .cardTitle {
    display: block;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 500;
}

.project hr {
    border: 1px solid var(--neon-shadow);
}

.project .cardText {
    display: block;
    text-align: left;
    font-size: 0.9rem;
    font-weight: 300;
}

/* MEDIA QUERIES */
@media (max-width: 768px) {
    .project .cardTitle {
        font-size: 0.8rem;
    }

    .project .cardText {
        font-size: 0.7rem;
    }

    .project {
        height: calc(330px * 1.375);
    }
}
