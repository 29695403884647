@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
    --navbar-background: #003459;
    --portfolio-background: #00171f;
    --neon-text: #ffffff;
    --neon-shadow: #4895ef;
    --carolina-blue: rgb(0, 168, 232);
    --color1: #ffffff;
    --color2: #00171f;
    --color3: #003459;
    --color4: #007ea7;
    --color5: #00a8e8;
    --dark-grey: #404040;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 18px;
}

body {
    width: 100%;
    min-height: 100vh;
    background-color: var(--portfolio-background);
}

h1 {
    font-size: 4.5rem;
}

h2 {
    font-size: 2.5rem;
}

h3 {
    font-size: 1.5rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    letter-spacing: 0.3rem;
}
