/* WELCOME SECTION */
#welcome-section {
    padding-top: 75px;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
}

#neon-container {
    width: 50%;
    min-width: 330px;
    height: fit-content;
    border: 0.1rem solid var(--neon-text);
    border-radius: 2rem;
    padding: 0.5em 3em;
    box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff, 0 0 2rem var(--neon-shadow),
        0 0 0.8rem var(--neon-shadow), 0 0 2.8rem var(--neon-shadow),
        inset 0 0 1.3rem var(--neon-shadow);
    animation: border-pulsate 2.5s infinite alternate;
}

.neon-text {
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    letter-spacing: 0.3rem;
    color: var(--neon-text);
    text-shadow: 0 0 4px var(--neon-text), 0 0 11px var(--neon-text),
        0 0 19px var(--neon-text), 0 0 40px var(--neon-shadow),
        0 0 80px var(--neon-shadow), 0 0 90px var(--neon-shadow),
        0 0 100px var(--neon-shadow), 0 0 150px var(--neon-shadow);
    animation: text-pulsate 2.5s infinite alternate;
    user-select: none;
}

#neon-container:hover {
    animation: border-flicker 1.5s infinite alternate;
    cursor: none;
}

#neon-container:hover .neon-text {
    animation: text-flicker 1.5s infinite alternate;
}

#about-container {
    width: 40%;
    min-width: 330px;
    height: fit-content;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    letter-spacing: 0.3rem;
    color: var(--neon-text);
}

#about-container > p {
    font-size: 0.8rem;
}

/* PROJECTS */
#projects {
    padding-top: 100px;
    padding-left: 7%;
    padding-right: 7%;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    flex-wrap: wrap;
}

/* PROFILE LINK */
#contact-section {
    padding-top: 75px;
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: grid;
    place-content: center;
}

#links-container ul {
    list-style: none;
}

#links-container a {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 1.5rem;
    letter-spacing: 0.4rem;
    color: var(--neon-text);
    text-decoration: none;
}

#profile-links li {
    margin: 25px 0;
}

/* FOOTER */
footer {
    position: absolute;
    margin: 0;
    padding: 0;
    bottom: 0;
    width: 100%;
    height: 75px;
    background-color: var(--navbar-background);
}

footer p {
    margin: 0;
    padding-top: 25px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: 0.3rem;
    color: var(--neon-text);
    text-align: center;
}

/* ANIMATION */
@keyframes text-pulsate {
    0% {
        text-shadow: 0 0 4px var(--neon-text), 0 0 11px var(--neon-text),
            0 0 19px var(--neon-text), 0 0 40px var(--neon-shadow),
            0 0 80px var(--neon-shadow), 0 0 90px var(--neon-shadow),
            0 0 100px var(--neon-shadow), 0 0 150px var(--neon-shadow);
    }
    100% {
        text-shadow: 0 0 2px var(--neon-text), 0 0 4px var(--neon-text),
            0 0 6px var(--neon-text), 0 0 10px var(--neon-shadow),
            0 0 45px var(--neon-shadow), 0 0 55px var(--neon-shadow),
            0 0 70px var(--neon-shadow), 0 0 80px var(--neon-shadow);
    }
}

@keyframes text-flicker {
    0%,
    18%,
    22%,
    25%,
    53%,
    57%,
    100% {
        text-shadow: 0 0 4px var(--neon-text), 0 0 11px var(--neon-text),
            0 0 19px var(--neon-text), 0 0 40px var(--neon-shadow),
            0 0 80px var(--neon-shadow), 0 0 90px var(--neon-shadow),
            0 0 100px var(--neon-shadow), 0 0 150px var(--neon-shadow);
    }
    20%,
    24%,
    55% {
        text-shadow: none;
    }
}

@keyframes border-pulsate {
    0% {
        box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff,
            0 0 2rem var(--neon-shadow), 0 0 0.8rem var(--neon-shadow),
            0 0 2.8rem var(--neon-shadow), inset 0 0 1.3rem var(--neon-shadow);
    }
    100% {
        box-shadow: 0 0 0.1rem #fff, 0 0 0.1rem #fff,
            0 0 1rem var(--neon-shadow), 0 0 0.5rem var(--neon-shadow),
            0 0 1rem var(--neon-shadow), inset 0 0 0.5rem var(--neon-shadow);
    }
}

@keyframes border-flicker {
    0%,
    18%,
    22%,
    25%,
    53%,
    57%,
    100% {
        box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff,
            0 0 2rem var(--neon-shadow), 0 0 0.8rem var(--neon-shadow),
            0 0 2.8rem var(--neon-shadow), inset 0 0 1.3rem var(--neon-shadow);
    }
    20%,
    24%,
    55% {
        box-shadow: none;
    }
}

/* MEDIA QUERIES */
@media (max-width: 768px) {
    #welcome-section {
        padding-top: 100px;
    }

    #neon-container {
        padding: 5%;
    }

    #neon-container h1 {
        font-size: 3.5rem;
    }

    #neon-container h2 {
        font-size: 2rem;
    }

    #about-container {
        text-align: center;
    }

    #about-container h3 {
        font-size: 1.25rem;
    }

    #about-container p {
        font-size: 0.8rem;
    }

    #profile-links li a {
        font-size: 1rem;
    }

    #foot p {
        font-size: 0.5rem;
    }
}
