.returnToTop {
    position: fixed;
    z-index: 99;
    bottom: 20px;
    right: 20px;
    /* background: rgba(250, 176, 61, 0.8); */
    background: rgba(0, 168, 232, 0.8);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    text-decoration: none;
    cursor: pointer;
}

.chevronUpIcon {
    color: #fff;
    position: relative;
    top: 12px;
    font-size: 1.5rem;
    transition: all 0.3s ease;
}

.returnToTop:hover {
    /* background: rgb(250, 176, 61); */
    background: rgba(0, 168, 232, 1);
}
.returnToTop:hover .chevronUpIcon {
    top: 1px;
}
