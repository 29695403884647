/* NAVBAR */
.navbar {
    position: fixed;
    width: 100%;
    height: 75px;
    background-color: var(--navbar-background);
    z-index: 999;
}

.navbar ul {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0 5vw 0 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 7%;
    list-style-type: none;
}

.navbar a {
    position: relative;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: 0.4rem;
    color: var(--neon-text);
    text-decoration: none;
    padding: 0 10px;
}

.navbar a::after {
    position: absolute;
    content: "";
    background-color: #fff;
    height: 0.1rem;
    width: 0;
    left: 0;
    bottom: -10px;
    transition: 0.3s;
    box-shadow: 0 0 4px var(--neon-text), 0 0 11px var(--neon-text),
        0 0 19px var(--neon-text), 0 0 40px var(--neon-shadow),
        0 0 80px var(--neon-shadow), 0 0 90px var(--neon-shadow),
        0 0 100px var(--neon-shadow), 0 0 150px var(--neon-shadow);
}

.navbar a:hover::after {
    width: 100%;
}

.navbar a:hover {
    color: var(--neon-text);
    text-shadow: 0 0 4px var(--neon-text), 0 0 11px var(--neon-text),
        0 0 19px var(--neon-text), 0 0 40px var(--neon-shadow),
        0 0 80px var(--neon-shadow), 0 0 90px var(--neon-shadow),
        0 0 100px var(--neon-shadow), 0 0 150px var(--neon-shadow);
}

/* MEDIA QUERIES */
@media (max-width: 768px) {
    .navbar ul {
        justify-content: center;
        padding: 0 2vw 0 2vw;
        gap: 2%;
    }
    .navbar a {
        font-size: 0.6rem;
    }
}
